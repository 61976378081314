

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL4PreLabPart4',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4_14: null,
      },
      questions: [
        {
          text: 'Some substances have similar properties. Take for example, carbon monoxide $\\ce{CO}$ and nitrogen $\\ce{N2}$ . List physical and/or chemical properties that both substances have in common. Then list physical and/or chemical properties that are different between the two substances. Why is it important for all people to be aware of BOTH the similarities and the differences between the two compounds?',
          inputName: 'part4_14',
        },
      ],
    };
  },
};
